<template>
    <div class="box">
        <div class="message">
            <h3><p></p><span>订单信息</span></h3>
            <div class="dd_content"><span class="ddstatus_title">订单状态</span><span class="ddstatus_content">{{orderstatus(orderDetail.orderState)}}</span></div>
            <div class="dd_content"><span class="ddstatus_title">订单编号</span><span class="ddstatus_content">{{orderDetail.orderNo}}</span></div>
            <div class="dd_content"><span class="ddstatus_title">创建时间</span><span class="ddstatus_content">{{orderDetail.createTime}}</span></div>
        </div>

        <div style="margin-bottom: 20px;">
            <div class="table_title">
                <div style="width: 35%">商品</div>
                <div style="width: 15%">属性</div>
                <div style="width: 10%">单价</div>
                <div style="width: 10%">数量</div>
                <div style="width: 12%">总价</div>
                <div style="width: 18%">实收款</div>
            </div>
            <div class="table_content" v-for="(item,index) in orderDetail.uocOrderGoodsRspBOList" :key="index">
                <div style="width: 35%; display: flex; ">
                    <img style="width: 90px; height: 90px; flex-shrink: 1" :src="item.spuPicUrl" alt="">
                    <p style="flex: 1; text-align: left; line-height: 20px">{{item.spuName}}</p>
                </div>

                <div style="width: 15%">
                    <p>{{item.attrNames}}</p>
                </div>

                <div style="width: 10%">
                    <p>{{item.price / 10000}}</p>
                </div>

                <div style="width: 10%">
                    <p>{{item.productAmount}}</p>
                </div>

                <div style="width: 12%">
                    <p>{{(item.price / 10000)*item.productAmount}}</p>
                </div>

                <div style="width: 18%; color: #DC3E3E; font-size: 18px" v-if="index == 0">
                    ￥{{orderDetail.totalPurchaseFee / 10000}}
                    <p style="color: #999; font-size: 14px">（含快递费￥0）</p>
                </div>
            </div>
        </div>


        <div class="message">
            <h3><p></p><span>物流信息</span></h3>
            <div class="wlmessage">
                <div style="flex-shrink: 0;">
                    <div class="dd_content"><span class="ddstatus_title1">收货人</span><span class="ddstatus_content" style="width: 312px">{{orderDetail.consignee}}</span></div>
                    <div class="dd_content"><span class="ddstatus_title1">联系电话</span><span class="ddstatus_content" style="width: 312px">{{orderDetail.mobile}}</span></div>
                    <div class="dd_content"><span class="ddstatus_title1">详细地址</span><span class="ddstatus_content" style="width: 312px">{{orderDetail.address}}</span></div>
                    <div class="dd_content"><span class="ddstatus_title1">快递公司</span><span class="ddstatus_content" style="width: 312px">{{orderDetail.expressComName}}</span></div>
                    <div class="dd_content"><span class="ddstatus_title1">运单号</span><span class="ddstatus_content" style="width: 312px">{{orderDetail.expressNo}}</span></div>
                </div>
                <div class="wlcontent">
                    <h6>实时物流</h6>
                    <div v-for="(item,index) in wlList" :key="index" :style="{fontWeight: index == 0 ? '600' : '400'}" style="margin-top: 20px; line-height: 26px; display: flex;"><p style="flex-shrink: 0; margin-right: 20px">{{item.ftime}}</p><p>{{item.context}}</p></div>
                </div>
            </div>
        </div>

        <div class="message">
            <h3><p></p><span>开票信息</span></h3>
            <div class="dd_content"><span class="ddstatus_title1">开票状态</span><span class="ddstatus_content" :style="{color: orderDetail.isInvoice == 1 ? '#366713' : '#E32319'}">{{orderDetail.isInvoice == 1 ? '已开票' : '待开票'}}</span></div>
            <div class="dd_content"><span class="ddstatus_title1">发票类型</span><span class="ddstatus_content">{{invoiceObj.invoiceRecordRspBO.invoiceType == 0 ? '普通发票' : (invoiceObj.invoiceRecordRspBO.invoiceType == 1 ? '增值税专用发票' : '--')}}</span></div>
            <!-- <div class="dd_content"><span class="ddstatus_title1">发票内容</span><span class="ddstatus_content">asdasdad</span></div> -->
            <div class="dd_content"><span class="ddstatus_title1">抬头类型</span><span class="ddstatus_content">{{invoiceObj.invoiceRecordRspBO.titleType == 0 ? '单位' : (invoiceObj.invoiceRecordRspBO.titleType == 1 ? '个人' : '--')}}</span></div>
            <div class="dd_content"><span class="ddstatus_title1">发票抬头</span><span class="ddstatus_content">{{invoiceObj.invoiceRecordRspBO.invoiceTitle ? invoiceObj.invoiceRecordRspBO.invoiceTitle : '--'}}</span></div>
            <div class="dd_content"><span class="ddstatus_title1">税号</span><span class="ddstatus_content">{{invoiceObj.invoiceRecordRspBO.tax ? invoiceObj.invoiceRecordRspBO.tax : '--'}}</span></div>
            <!-- <div class="dd_content"><span class="ddstatus_title1">邮箱</span><span class="ddstatus_content">asdasdad</span></div> -->
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            orderDetail: {},
            invoiceObj: {
                invoiceRecordRspBO: {
                    invoiceType: '',
                    titleType: '',
                    invoiceTitle: '',
                    tax: ''
                }
            },
            wlList: [],
        }
    },
    mounted() {
        this.qryGoods();
    },
    methods: {
        qryGoods(){
            this.qa.queryUocOrderInfoById({id: this.$route.query.id}).then(res => {
                this.orderDetail = res.data;
                this.qa.queryExpressInfo({expressComNo: res.data.expressComNo, expressNo: res.data.expressNo}).then(req => {
                     this.wlList = JSON.parse(req.data.expressInfo).data;
                })

                this.qa.queryByInvoiceOrderRel({orderNo: res.data.orderNo}).then(rem => {
                    this.invoiceObj = rem.data;
                })
            })

            
        },
        orderstatus(status){
            if(status == 0) {
                return '创建'
            } else if(status == 1) {
                return '待付款'
            } else if(status == 2) {
                return '待发货'
            } else if(status == 3) {
                return '待收货'
            } else if(status == 4) {
                return '待评价'
            } else if(status == 5) {
                return '已完成'
            } else if(status == 6) {
                return '已关闭/已取消'
            } else if(status == 7) {
                return '售后中'
            }
        },
    }
}
</script>

<style scoped>
  .box {
      width: 100%;
      padding: 20px;
      background: #fff;
      border-radius: 8px;
  }
  .message {
      margin-bottom: 20px;
  }
  .message h3 {
      display: flex;
      height: 40px;
      background: #EFFAE8;
      line-height: 40px;
      color: #333;
      align-items: center;
      padding: 0 20px;
  }
  .message h3 >p {
      width: 4px;
      height: 14px;
      background: #84C754;
      border-radius: 2px;
      margin-right: 8px;
  }
  .dd_content {
      border: 1px solid #EFFAE8;
      display: flex;
  }
  .dd_content .ddstatus_title {
      display: block;
      padding: 20px 35px;
      border-right: 1px solid #EFFAE8;
  }
  .dd_content .ddstatus_title1 {
      display: block;
      padding: 20px 0;
      border-right: 1px solid #EFFAE8;
      width: 120px;
      text-align: center;
  }
  .dd_content .ddstatus_content {
      display: block;
      padding: 20px 28px;
  }
  .table_title {
      width: 100%;
      background: #EFFAE8;
      height: 40px;
      line-height: 40px;
      display: flex;
      text-align: center;
      
  }
  .table_content {
      display: flex;
      flex-wrap: wrap;
  }
  .table_content >div{
      padding: 20px 10px;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      
  }
  .table_content >div >img {
      margin-right: 20px;
  }
  .wlmessage {
      display: flex;
      height: 400px;
  }
  .wlcontent {
      flex: 1;
      padding: 20px 28px;
      height: 400px;
      overflow: auto;
  }
</style>